import { useMemo, useEffect, useRef } from 'react';

import {
  useFormik,
  FormikErrors,
  FormikValues,
  FormikConfig,
  FormikHelpers,
  getIn,
  setIn,
} from 'formik';
import { SchemaOf, ObjectSchema } from 'yup';

/**
 * Конфигурация формы.
 */
type Config<TValues extends FormikValues> = Omit<
  FormikConfig<TValues>,
  'onSubmit' | 'initialValues' | 'validationSchema' | 'validate'
> & {
  /**
   * Уникальное в рамках страницы имя формы.
   */
  name: string;

  /**
   * Указывает, что следует запустить повторную валидацию формы, если объект,
   * переданный в свойство `validationSchema`, изменился (то есть, если
   * поменялись правила валидации).
   *
   * Таким образом мы получаем возможность менять правила валидации на лету,
   * и форма будет реагировать на это.
   * @default false
   */
  validateOnSchemaChange?: boolean;

  /**
   * Схема валидации формы.
   */
  validationSchema: SchemaOf<TValues>;

  /**
   * Значения полей формы по умолчанию. Если не заданы, аналогичные значения
   * будут получены из схемы валидации.
   */
  initialValues?: TValues;

  /**
   * Обрабатывает успешную отправку формы.
   * @param values Значения полей формы.
   * @param helpers Коллекция вспомогательных методов и свойств Formik.
   */
  onSubmit?: (
    values: TValues,
    helpers: FormikHelpers<TValues>,
  ) => void | Promise<void>;
};

/**
 * Генерирует идентификатор элемента HTML в нотации `camelCase` путём склеивания
 * указанных слов.
 * @param words Список слов.
 */
function concatId(...words: string[]) {
  const { length } = words;
  let id: string = '';

  for (let i = 0; i < length; i += 1) {
    let value = words[i];

    if (value) {
      if (i > 0) {
        const head = value.charAt(0).toUpperCase();
        const tail = value.substr(1);
        value = `${head}${tail}`;
      }

      id += value;
    }
  }

  return id;
}

/**
 * Создаёт функцию валидации формы на основе указанной схемы валидации.
 * Проблема стандартного механизма валидации Formik в том, что он запускает
 * проверку сразу всех полей формы в асинхронном режиме. Из-за этого
 * срабатывают сразу _все_ валидаторы схемы. Это, в свою очередь, приводит
 * к ситуациям, когда, к примеру, первый валидатор, проверяющий формат значения
 * поля ввода, выдаёт ошибку, а следующий по цепочке, ожидающий, что формат
 * значения верен, падает с исключением.
 * Чтобы решить эту проблему, функция запуска схемы была переписана - теперь
 * схема запускается для каждого поля по-отдельности, и в синхронном режиме.
 * @param schema Схема валидации.
 */
function makeValidate(schema: ObjectSchema<any>) {
  const fields = Object.keys(schema.fields);
  const { length: fieldsCount } = fields;

  return function validate<TValues extends FormikValues>(values: TValues) {
    let formErrors: FormikErrors<TValues> = {};

    for (let i = 0; i < fieldsCount; i += 1) {
      const field = fields[i];

      try {
        schema.validateSyncAt(field, values, { abortEarly: true });
      } catch (e) {
        const exception = e as any;

        // Данный код украден из исходников Yup, смотри
        // https://github.com/formium/formik/blob/b33e318e15dea221d16ced97d4c90c0ad9c26a96/packages/formik/src/Formik.tsx#L1047
        if (exception.name !== 'ValidationError') {
          throw exception;
        }

        const { inner: errorsList } = exception;

        if (errorsList) {
          const { length: errorsCount } = errorsList;

          if (errorsCount === 0) {
            formErrors = setIn(formErrors, exception.path, exception.message);
          } else {
            for (let j = 0; j < errorsCount; j += 1) {
              const error = errorsList[j];

              if (!getIn(formErrors, error.path)) {
                formErrors = setIn(formErrors, error.path, error.message);
              }
            }
          }
        }
      }
    }

    return formErrors;
  };
}

/**
 * Возвращает новую коллекцию значений, аналогичную указанной, у которой все
 * `null` или `undefined` заменены на переданное фиксированное значение.
 * @param values Коллекция значений формы.
 */
function replaceVoids<TValues extends FormikValues>(
  values: TValues,
  replacement: null | undefined,
) {
  const fields = Object.keys(values);
  const { length } = fields;
  const nextValues = {} as TValues;

  for (let i = 0; i < length; i += 1) {
    const field = fields[i] as keyof TValues;
    const value = values[field];
    // @ts-ignore
    nextValues[field] = value == null ? replacement : value;
  }

  return nextValues;
}

/**
 * Возвращает новую коллекцию значений, аналогичную указанной, у которой все
 * `null` заменены на `undefined`.
 * @param values Коллекция значений формы.
 */
function replaceNulls<TValues extends FormikValues>(values: TValues) {
  return replaceVoids(values, undefined);
}

/**
 * Возвращает новую коллекцию значений, аналогичную указанной, у которой все
 * `undefined` заменены на `null`.
 * @param values Коллекция значений формы.
 */
function replaceUndefineds<TValues extends FormikValues>(values: TValues) {
  return replaceVoids(values, null);
}

/**
 * Возвращает объект состояния формы. Фактически, данная функция служит обёрктой
 * над функцией `useFormik` из библиотекой `formik`, но немного расширяет её
 * поведение.
 *
 * Во-первых, в результат добавляются функции, которые возвращают свойства,
 * подключающие элементы React к состоянию формы. Используется это так:
 * `<form {...formik.bindForm()} />`.
 *
 * Во-вторых, добавлено дополнительное условие валидации
 * `validateOnSchemaChange`, при котором форма будет проверена повтроно, если
 * схема валидации, переданная в `validationSchema`, изменилась.
 *
 * В-третьих, параметр `onSubmit` стал необязательным.
 *
 * В-четвёртых, форма научилась брать значения полей по умолчанию
 * непосредственно из схемы валидации.
 *
 * В-пятых, схема валидации `validationSchema` стала обязательным и получило
 * строгую типизацию.
 *
 * В-шестых, была убрана поддержка асинхронных валидаторов. Но функция
 * `validateForm` всё ещё возвращает Promise.
 * @param config Конфигурация формы.
 */
export default function useForm<TValues extends FormikValues>({
  validateOnSchemaChange = false,
  validationSchema,
  onSubmit: outerOnSubmit,
  initialValues: outerInitialValues,
  name: formName,
  ...config
}: Config<TValues>) {
  /**
   * Значения полей формы по умолчанию. Если не заданы в свойствах, берутся из
   * схемы валидации.
   */
  const initialValues =
    // @ts-ignore
    outerInitialValues ?? (validationSchema.getDefaultFromShape() as TValues);

  /**
   * Идентификатор формы.
   */
  const formId = concatId(formName, 'form');

  /**
   * Пользовательская функция валидации (см. выше).
   */
  const validate = useMemo(
    () => makeValidate(validationSchema),
    [validationSchema],
  );

  /**
   * Обрабатывает успешной отправку валидной формы.
   * @param formikValues Значения полей формы.
   * @param helpers Набор вспомогательных методов.
   */
  function onSubmit(formikValues: TValues, helpers: FormikHelpers<TValues>) {
    if (outerOnSubmit == null) {
      return;
    }

    const values = replaceNulls(formikValues);
    outerOnSubmit(values, helpers);
  }

  /**
   * Исходный объект состояния формы.
   */
  const formik = useFormik({
    ...config,
    initialValues: replaceUndefineds(initialValues),
    validate,
    onSubmit,
  });

  /**
   * Статус валидации формы. `'none'` означает, что процесс валидации формы ещё
   * ни разу не запускался. `'validating'` означает, что валидация идёт в
   * данный момент. И, наконец, `'validated'` говорит нам, что валидация формы
   * завершена.
   *
   * Используется для реализации условия `validateOnSchemaChange`.
   */
  const validationStatus = useRef<'none' | 'validating' | 'validated'>('none');
  const { isValidating } = formik;

  useEffect(() => {
    if (validationStatus.current === 'none') {
      validationStatus.current = isValidating ? 'validating' : 'none';
    } else {
      validationStatus.current = isValidating ? 'validating' : 'validated';
    }
  }, [isValidating]);

  /**
   * Важно: мы оборачиваем функцию валидации в формы в mutable ref для того,
   * чтобы последующие `useEffect` не запускались, если эта функция изменится.
   * При этом в `validateFormRef.current` всегда будет актуальная функция
   * валидации.
   */
  const validateFormRef = useRef<() => void>(formik.validateForm);
  validateFormRef.current = formik.validateForm;

  /**
   * Так же мы поступаем и с самим флагом `validateOnSchemaChange` - чтобы не
   * вводить следующий `useEffect` в заблуждение.
   */
  const validateOnSchemaChangeRef = useRef<boolean>(validateOnSchemaChange);
  validateOnSchemaChangeRef.current = validateOnSchemaChange;

  /**
   * И, наконец, реализация валидации по `validateOnSchemaChange`. Данный
   * эффект будет выполняться только если: раз - форма уже валидировалась
   * (и валидация не происходят прямо сейчас), два - флаг из настроек формы
   * взведён, и три - изменилось значение `validationSchema`.
   */
  useEffect(() => {
    if (
      validateOnSchemaChangeRef.current &&
      validationStatus.current === 'validated'
    ) {
      validateFormRef.current();
    }
  }, [validationSchema]);

  /**
   * Возвращает значение флага `touched` для указанного поля ввода. Данный флаг
   * означает, что пользователь уже помещал поле ввода в фокус.
   * @param name Название поля ввода.
   */
  function touchedOf(name: keyof TValues) {
    return getIn(formik.touched, name as string);
  }

  /**
   * Возвращает текущее значение указанного поля ввода.
   * @param name Название поля ввода.
   */
  function valueOf<TKey extends keyof TValues>(name: TKey) {
    return getIn(formik.values, name as string) as TValues[TKey];
  }

  /**
   * Возвращает текст ошибки указанного поля ввода. Если у данного поля нет
   * ошибок, возвращает `undefined`.
   * @param name Название поля ввода.
   */
  function errorOf<TKey extends keyof TValues>(name: TKey) {
    return getIn(formik.errors, name as string) as
      | FormikErrors<TKey>
      | undefined;
  }

  /**
   * Возвращает текст ошибки указанного поля ввода, при условии, что на
   * момент вызова данной функции текст ошибки должен быть показан на форме.
   * Если ошибки нет, или время показа ошибки ещё не наступило, то функция
   * вернёт `undefined`.
   * @param name Название поля ввода.
   */
  function showedErrorOf(name: keyof TValues) {
    const touched = touchedOf(name);
    const error = errorOf(name);

    const isShowed = touched && error != null;
    return isShowed ? error : undefined;
  }

  /**
   * Возвращает обработчик события `onChange` для указанного поля ввода.
   * @param name Название поля ввода.
   */
  function handleChangeOf(name: keyof TValues) {
    function onChange(data: any) {
      if (typeof data === 'object' && data != null && data.target != null) {
        formik.handleChange(data);
      } else {
        formik.setFieldValue(name as string, data == null ? null : data);
      }
    }

    return onChange;
  }

  /**
   * Возвращает обработчик события `onBlur` для указанного поля ввода.
   * @param name Название поля ввода.
   */
  function handleBlurOf(name: keyof TValues) {
    Boolean(name);
    return formik.handleBlur;
  }

  /**
   * Возвращает коллекцию свойств, которые подключают элемент формы в
   * контекст Formik.
   */
  function bindForm() {
    return {
      name: formName,
      id: formId,
      onSubmit: formik.handleSubmit,
      onReset: formik.handleReset,
      noValidate: true,
    };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают кнопку отправки формы
   * в контекст Formik.
   */
  function bindSubmitButton() {
    const id = concatId(formName, 'submit', 'button');

    return {
      id,
      form: formId,
      type: 'submit' as 'submit',
    };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают кнопку очистки формы
   * в контекст Formik.
   */
  function bindResetButton() {
    const id = concatId(formName, 'reset', 'button');

    return {
      id,
      form: formId,
      type: 'reset' as 'reset',
    };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают компонент `FormControl`
   * к состояню формы.
   * @param name Название поля ввода.
   */
  function bindFormControl(name: keyof TValues) {
    const error = showedErrorOf(name) == null ? undefined : true;
    return { error };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают компонент `InputLabel`
   * к состояню формы.
   * @param name Название поля ввода.
   */
  function bindInputLabel(name: keyof TValues) {
    const htmlFor = concatId(formName, name as string);
    return { htmlFor };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают компонент `Input`
   * к состояню формы.
   * @param name Название поля ввода.
   */
  function bindInput(name: keyof TValues) {
    const id = concatId(formName, name as string);
    const value = valueOf(name);

    return {
      id,
      onChange: handleChangeOf(name),
      onBlur: handleBlurOf(name),
      value: value as any,
      name: name as string,
    };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают поле ввода `TextField`
   * в контекст Formik.
   * @param name Название поля ввода.
   */
  function bindTextField(name: keyof TValues) {
    const errorMessage = showedErrorOf(name);

    return {
      ...bindInput(name),
      helperText: errorMessage,
      error: errorMessage != null,
    };
  }

  /**
   * Возвращает коллекцию свойств, которые подключают поле ввода `Switch` и его
   * производные в контекст Formik.
   * @param name Название поля ввода.
   */
  function bindSwitch(name: keyof TValues) {
    const { value, ...props } = bindInput(name);

    return {
      ...props,
      checked: value as boolean,
    };
  }

  return {
    ...formik,
    bindSubmitButton,
    bindResetButton,
    bindFormControl,
    bindInputLabel,
    bindTextField,
    bindSwitch,
    bindInput,
    bindForm,
    handleChangeOf,
    handleBlurOf,
    showedErrorOf,
    touchedOf,
    errorOf,
    valueOf,
  };
}
